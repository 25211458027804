<template>
  <ConnectWalletBase url="selectLockToken">
    <template v-slot:content>
      <div class="d-flex flex-column align-center mb-1">
        <v-img
          alt="Vuetify Logo"
          contain
          :src="require(`@/assets/locker.svg`)"
          transition="scale-transition"
          max-width="100px"
        />
      </div>
      <div class="text-lg text-center">Token Locker</div>
      <div class="text-caption text-caption my-4">
        Introducing Token Vesting Locking Pools. Token locks are represented as shares of a pool, allowing all ERC20
        tokens including Rebasing and Deflationary mechanisms to be supported.
      </div>
      <div class="text-caption mb-9">
        This means lock amounts may change due to decimal rounding, but you will always retain your share of the pool.
      </div>
    </template>
  </ConnectWalletBase>
</template>
<script>
import ConnectWalletBase from '../ConnectWalletBase.vue'
export default {
  props: {
    url: String,
  },
  components: {
    ConnectWalletBase,
  },
  data() {
    return {}
  },
}
</script>